<template>
  <modal 
    class="apply-modal  modal-without-footer"
    name="apply-modal"
    @closed="$emit('updateComparisonData')"
    @before-close="beforeClose"
    @before-open="beforeOpen"
    transition="fade"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    width="30%"
    height="30%"
  >
    <div id="modal-header" class="action-modal">
      <div class="w-75 d-flex">
          <p>Կցել հայտը</p>
      </div>
      <button type="button"
            class="close position-absolute"
              style="right: 1.25rem; top: 25%"
              @click="$modal.hide('apply-modal')"
              aria-label="Close"
      >
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 1.64102L14.859 0L8.49997 6.359L2.14102 4.62582e-05L0.5 1.64107L6.85895 8.00002L0.500034 14.3589L2.14105 16L8.49997 9.64104L14.8589 16L16.5 14.359L10.141 8.00002L16.5 1.64102Z" fill="#ABAEB8"/>
          </svg>
      </button>
    </div>
    <div id="modal-body">
      <div class="row m-0 p-0 justify-content-center h-100 align-items-center">
        <div class="col-auto my-2 p-0 ">
          <a v-if="organize && organize.additional_file && organize.additional_file !== ''" :href="organize.additional_file" download target="blank" class="btn btn-light my-2 text-primary">
            <svg class=" mr-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.91797 19.1429C4.91797 20.0357 5.63225 20.75 6.52511 20.75H18.9358C19.8287 20.75 20.543 20.0357 20.543 19.1429V4.85714C20.543 3.96429 19.8287 3.25 18.9358 3.25H6.52511C5.63225 3.25 4.91797 3.96429 4.91797 4.85714V19.1429ZM19.1144 19.3214H6.34654V4.67857H19.1144V19.3214ZM8.75728 7.08943H16.793V8.518H8.75728V7.08943ZM16.793 15.482H8.75728V16.9106H16.793V15.482ZM8.75728 11.2857H16.793V12.7143H8.75728V11.2857Z" fill="#006BE6"/>
            </svg>
            <span>Կցված փաստաթղթեր</span>
          </a>
          <div class="d-flex align-items-center justify-content-center">
            <label class="btn m-0" :class="[uploadFileLoading && 'disabled', additionalFile ? 'btn-light' : 'btn-primary']">
              <i class="fa-solid fa-file-arrow-up mr-2"></i>
              <span>Կցել փաստաթղթեր</span>
              <input @change="handleUploadFile" type="file" hidden>
              <span v-if="uploadFileLoading" class="ml-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </label>
          </div>
          <div class="mt-2">
            <div v-if="additionalFile" class="multiselect__tag" style="padding-top: 12px">
              <span>
                <a :href="additionalFile" download target="_blank" class="text-decoration-none text-secondary">
                  {{additionalFile.split('/')[additionalFile.split('/').length - 1]}}
                </a>
              </span>
              <span class="multiselect__tag-icon">
                <div @click="deleteAdditionalFile" :class="isResponded && 'disabled'" class="position-relative w-100 h-100">
                  <svg data-v-e720bbc8="" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path data-v-e720bbc8="" fill-rule="evenodd" clip-rule="evenodd" d="M9.45436 8.32453L8.32616 9.45273L5.00095 6.12752L1.67557 9.45291L0.547363 8.32471L3.87275 4.99932L0.547987 1.67456L1.67619 0.546356L5.00095 3.87112L8.32554 0.546533L9.45374 1.67474L6.12915 4.99932L9.45436 8.32453Z" fill="#595E6B"></path>
                  </svg>
                </div>
              </span>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import isMobile from '@/mixins/isMobileView'
import purchaseTypesForItenderLongNames from '@/mixins/purchaseTypes'

export default {
  name: 'SuggestionModal',
  mixins: [isMobile, purchaseTypesForItenderLongNames],
  data () {
    return {
      uploadedFileName: '',
      additionalFile: '',
      tenderData: {},
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/me']
    },
  },
  methods: {
    consoleLog(data){
      console.log(data)
    },
    handleUploadFile(event){
      const file = event.target.files[0]
      if(file.size < 100000000){
        this.uploadFileLoading = true;
        const uploadedFile = file;
        const formData = new FormData();
        formData.append('file', uploadedFile);
        this.$client.post(`tender/uploadSuggestionFile/${this.tenderData.id}`, formData).then(({data}) => {
          this.additionalFile = data.additional_file
          if(data){
            this.uploadFileLoading = false;
            this.$notify({
              group: 'foo',
              type: 'success',
              text: 'Հաջողությամբ հաստատված է'
            })
          }
        })
        this.uploadedFileName = file.name;
      } else {
        this.$notify({
          group: 'foo',
          type: 'error',
          text: 'Ընտրված ֆայլի առավելագույն ծավալը պետք է լինի 10ՄԲ'
        })
      }
    },
    deleteAdditionalFile(){
      this.$client.post(`tender/deleteAdditionalFile/${this.tenderData.id}`).then(({data}) => {
        this.additionalFile = data.additional_file
        if(data){
          this.$notify({
            group: 'foo',
            type: 'success',
            text: 'Հաջողությամբ ջնջված է'
          })
        }
      })
    },
    beforeClose(){
      this.isNewSuggestion = true;
      this.isResponded = false;
      // this.resetComponent()
      if(!this.organize.winner_by_lots){
        this.data.pop()
      }
    },
    beforeOpen({params}) {
      this.tenderData = params;
      this.resetComponent = params.resetComponent
      this.isItender = true;
      this.$client.get(`tender/getSuggestionFile/${this.tenderData.id}`).then(({data}) => {
        this.additionalFile = data.additional_file
      })
    },
  }
}
</script>
<style>
.suggestion-table td{
  padding: 5px 2px;
}
</style>
<style scoped>
  .multiselect__tag{
    margin: 0;
    background: #F7F7F7;
    border-radius: 12px !important;
  }
  .first-table td{
    font-size: 13px;
    line-height: 18px;
    color: #40444D;
  }
  .first-table th{
    font-size: 13px;
    line-height: 18px;
    color: #595E6B;
  }
  .second-table th{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #595E6B;
  }
  .second-table td{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2D3036;
  }
  .third-table th{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #595E6B;
  }
  .third-table td{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #40444D;
  }
  @media (max-width: 768px) {
    .margin-top-mobile{
      margin-top: 10px;
    }
    .mobile-confirm-btn-block{
      margin-top: 20px;
    }
    .confirm-btn-block{
      margin-left: 0;
    }
    .apply-modal #modal-footer .btn{
      width: 100%;
      padding-right: 28px;
    }
    .apply-modal #modal-footer .btn:first-child{
      margin-bottom: 18px;
    }
    .apply-modal #modal-body{
      height: calc(100% - 56px - 168px) !important;
    }
    .apply-modal /deep/ .vm--modal {
      width: auto !important;
      height: 95vh !important;
      top: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      margin: 30px 8px;
      position: absolute;
    }
  }
  .searchItem:hover {
    background-color: #006BE6;
    color: #fff;
  }
  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .2s ease;
  }
  .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

